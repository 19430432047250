<template>
	<div class="trip__cards trip-cards">
		<p v-if="loading" class="trip-cards__zero-text">Loading...</p>

		<div
			v-else-if="values.items && values.items.length"
			class="trip-cards__list"
		>
			<TripCard v-for="itm in values.items" :key="itm.id" :content="itm" />
		</div>

    <div v-else>
      <p class="trip-cards__zero-text">
        У вас ещё нет предстоящих поездок. Найдите и забронируйте жильё.
      </p>
      <tk-link
        kind="title-btn"
        class="trip-title__toggle"
        to="/places?city=c2deb16a-0330-4f05-821f-1d09c93331e6&children=0&guests=1"
      >
        Найти жилье
      </tk-link>
    </div>

		<tk-pagination
			:content="values.meta"
			class="trip-cards__pagination"
			:offset-page="+page"
			@next="changePage"
		/>
	</div>
</template>

<script>
import TripCard from '@/components/cards/Trip'

export default {
	components: { TripCard },
	data() {
		return {
			values: {},
			loading: true,

      getAbortController: new AbortController(),
		}
	},
	async beforeMount() {
		await this.get()
	},
	computed: {
		page() {
			return this.$route.query.page || 1
		}
	},
	methods: {
		async get(payload) {
      this.getAbortController.abort()
      this.getAbortController = new AbortController()
			const response = await this.$api.reserve.get({
				page: payload ? payload.page : this.page,
				type: 'upcoming'
			}, { controller: this.getAbortController })

			this.loading = false

			if (!response.status) {
				return
			}

			this.values = response.response
		},
		async changePage(payload) {
			this.$router.push({ query: { page: payload } })

			await this.get({ page: payload })
		}
	},
  beforeUnmount() {
    this.getAbortController.abort()
  }
}
</script>

<style lang="sass">
.trip-cards
  &__list
    display: grid
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr))
    grid-column-gap: 20px
    grid-row-gap: 30px
    justify-content: center
    margin-bottom: 0
    padding-left: 0
    list-style: none

  &__zero-text
    max-width: 303px

    font-weight: 500
    font-size: 14px
    line-height: 16px
    color: $gray-text

  &__pagination
    margin-top: 155px

@media screen and (max-width: 800px)
  .trip-cards
    &__list
      justify-items: center
</style>
